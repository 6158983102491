import {
  Box,
  Stack,
  Text,
  Link,
  Flex,
  Image,
  IconButton,
} from "@chakra-ui/react";
import { FaFacebook, FaInstagram, FaYoutube } from "react-icons/fa";
import logo from "../../assets/logo/logo_white.png";
import { lightColor } from "./LandingPage";

const Footer = () => {
  return (
    <Box bg="gray.900" color="gray.200" py={10}>
      <Flex
        justify="space-between"
        align="center"
        maxW="1200px"
        mx="auto"
        px={6}
        flexWrap="wrap"
        direction={["column", "row"]}
      >
        <Stack
          direction={["column", "row"]}
          spacing={6}
          mb={[4, 8]}
          textAlign={["center", "left"]}
          width={["100%", "auto"]}
        >
          <Link
            href="#integration"
            _hover={{ textDecoration: "underline", color: "white" }}
          >
            Hogyan működik
          </Link>
          <Link
            href="#features"
            _hover={{ textDecoration: "underline", color: "white" }}
          >
            Funkciók
          </Link>
          <Link
            href="#usage"
            _hover={{ textDecoration: "underline", color: "white" }}
          >
            Kiknek?
          </Link>
          <Link
            href="#pricing"
            _hover={{ textDecoration: "underline", color: "white" }}
          >
            Árak
          </Link>
          <Link
            href="#contact"
            _hover={{ textDecoration: "underline", color: "white" }}
          >
            Kapcsolat
          </Link>
        </Stack>

        <Stack
          direction={["column", "row"]}
          spacing={6}
          textAlign={["center", "left"]}
          width={["100%", "auto"]}
          mb={[0, 4]}
        >
          <Link
            href="https://app.wobble.hu/adatvedelem"
            _hover={{ textDecoration: "underline", color: "white" }}
            isExternal
          >
            Adatkezelési tájékoztató
          </Link>
          <Link
            href="https://app.wobble.hu/aszf"
            _hover={{ textDecoration: "underline", color: "white" }}
            isExternal
          >
            Általános Szerződési Feltételek
          </Link>
        </Stack>

        <Stack
          direction={["column", "row"]}
          spacing={6}
          textAlign={["center", "left"]}
          align={["center", "flex-start"]}
          mb={[4, 0]}
        >
          <Box mb={[4, 0]}>
            <Image src={logo} alt="Logo" h="50px" objectFit="contain" />
          </Box>
          <Stack direction="row" spacing={4} justify={["center", "flex-start"]}>
            <IconButton
              icon={<FaFacebook />}
              as="a"
              href="https://www.facebook.com/people/Wobble-App/61566480490390/"
              aria-label="Facebook"
              bg="gray.900"
              color="white"
              target="_blank"
              size="lg"
              variant="outline"
              _hover={{ bg: "white", color: "gray.900" }}
            />
            <IconButton
              as="a"
              icon={<FaInstagram />}
              href="https://www.instagram.com/wobble_app/"
              aria-label="Instagram"
              bg="gray.900"
              color="white"
              target="_blank"
              size="lg"
              variant="outline"
              _hover={{ bg: "white", color: "gray.900" }}
            />
            <IconButton
              icon={<FaYoutube />}
              as="a"
              href="https://www.youtube.com/@WobbleAppHun/videos"
              aria-label="YouTube"
              bg="gray.900"
              color="white"
              target="_blank"
              size="lg"
              variant="outline"
              _hover={{ bg: "white", color: "gray.900" }}
            />
          </Stack>
        </Stack>
      </Flex>

      <Box mt={8} textAlign="center">
        <Text fontSize="sm" color={lightColor}>
          &copy; {new Date().getFullYear()} Miller Productions. Minden jog
          fenntartva.
        </Text>
      </Box>
    </Box>
  );
};

export default Footer;
