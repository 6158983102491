import { Box, Flex, Image, Stack, Text } from "@chakra-ui/react";
import { lightColor } from "./LandingPage";
import mockup_timeslot from "../../assets/images/mockup_timeslot.png";
import mockup_blog from "../../assets/images/mockup_blog.png";
import mockup_domain from "../../assets/images/mockup_cutsom_domain.png";
import mockup_dashboard from "../../assets/images/mockup_dashboard.png";
import mockup_email from "../../assets/images/mockup_email.png";
import mockup_info from "../../assets/images/mockup_info.png";
import SwipeableImage from "./SwipeableImage";

const EndPageSection = () => {
  return (
    <Flex
      direction="column"
      align="center"
      justify="center"
      bg={lightColor}
      color="white"
      textAlign="center"
      p={8}
    >
      <Flex direction="column" align="center" mt={{ base: 12, sm: 150 }}>
        <Text fontSize="4xl" fontWeight="bold" color="white" mb={4} as="h1">
          Funkciók, amik elmaradhatatlanok számodra
        </Text>
        <Stack
          direction={{ base: "column", md: "row" }}
          spacing={8}
          align="center"
          justify="center"
          maxW="7xl"
          mx="auto"
        >
          <Box
            flex="1"
            p={4}
            textAlign="center"
            m={4}
            boxShadow="2xl"
            rounded={20}
            bg="white"
            color="white"
            transition="all 0.3s ease"
            _hover={{
              transform: "scale(1.05)",
              zIndex: 1,
              color: "black",
            }}
          >
            <Image
              src={mockup_timeslot}
              alt="Időpontfoglaló rendszer felület"
              loading="lazy"
              w={{ base: "100%", md: "100%" }}
              mx="auto"
              mb={4}
            />
            <Text fontSize="xl" fontWeight="bold" as="h1" color="black">
              Időpontfoglaló
            </Text>
            <Text mt={2} as="h2" color="black">
              Nincs több hívogatás rossz időpontban, hosszú várakozás ügyfél
              válaszra, egyből mehet a foglalás
            </Text>
          </Box>
          <Box
            flex="1"
            p={4}
            textAlign="center"
            m={4}
            boxShadow="2xl"
            rounded={20}
            bg="white"
            color="white"
            transition="all 0.3s ease"
            _hover={{
              transform: "scale(1.05)",
              zIndex: 1,
              color: "black",
            }}
          >
            <Image
              src={mockup_info}
              alt="Egyéni megjelenés"
              loading="lazy"
              w={{ base: "100%", md: "100%" }}
              mx="auto"
              mb={4}
            />
            <Text fontSize="xl" fontWeight="bold" as="h1" color="black">
              Saját megjelenés
            </Text>
            <Text mt={2} as="h2" color="black">
              Blogposztok, képek, infó Rólad, értékelések, szolgáltatások
              listája, foglalás. Minden infó egy helyen, akár egy saját
              weboldalnál
            </Text>
          </Box>
        </Stack>
        <Stack
          direction={{ base: "column", md: "row" }}
          spacing={8}
          align="center"
          justify="center"
          maxW="7xl"
          mx="auto"
          mt={8}
        >
          <Box
            flex="1"
            p={4}
            textAlign="center"
            m={4}
            boxShadow="2xl"
            rounded={20}
            bg="white"
            color="white"
            transition="all 0.3s ease"
            _hover={{
              transform: "scale(1.05)",
              zIndex: 1,
              color: "black",
            }}
          >
            <Image
              src={mockup_dashboard}
              alt="Admin felületen minden tudsz kezelni"
              loading="lazy"
              w={{ base: "100%", md: "100%" }}
              mx="auto"
              mb={4}
            />
            <Text fontSize="xl" fontWeight="bold" as="h1" color="black">
              Admin felület
            </Text>
            <Text mt={2} as="h2" color="black">
              Kezeld szolgáltatásaidat, ügyfeleidet egy helyen, legyél napra
              kész a naptárad eseményeivel
            </Text>
          </Box>
          <Box
            flex="1"
            p={4}
            textAlign="center"
            m={4}
            boxShadow="2xl"
            rounded={20}
            bg="white"
            color="white"
            transition="all 0.3s ease"
            _hover={{
              transform: "scale(1.05)",
              zIndex: 1,
              color: "black",
            }}
          >
            <Image
              src={mockup_blog}
              alt="Írj blog posztot, oszd meg ügyfeleiddel"
              loading="lazy"
              w={{ base: "100%", md: "100%" }}
              mx="auto"
              mb={4}
            />
            <Text fontSize="xl" fontWeight="bold" as="h1" color="black">
              Blog posztok
            </Text>
            <Text mt={2} as="h2" color="black">
              Oszd meg mi történik vállalkozásodban, készíst posztot egy
              napodról, új szolgáltatásodról, sikereidről
            </Text>
          </Box>
        </Stack>
        <Stack
          direction={{ base: "column", md: "row" }}
          spacing={8}
          align="center"
          justify="center"
          maxW="7xl"
          mx="auto"
          mt={8}
        >
          <Box
            flex="1"
            p={4}
            textAlign="center"
            m={4}
            boxShadow="2xl"
            rounded={20}
            bg="white"
            color="white"
            transition="all 0.3s ease"
            _hover={{
              transform: "scale(1.05)",
              zIndex: 1,
              color: "black",
            }}
          >
            <Image
              src={mockup_domain}
              alt="Saját domain"
              loading="lazy"
              w={{ base: "100%", md: "100%" }}
              mx="auto"
              mb={4}
            />
            <Text fontSize="xl" fontWeight="bold" as="h1" color="black">
              Saját domain, vagy egyedi URL
            </Text>
            <Text mt={2} as="h2" color="black">
              Használd a saját domain neved, vagy használj egyedi url link
              megnevezést, az ingyenes SSL tanúsítványt mi adjuk
            </Text>
          </Box>
          <Box
            flex="1"
            p={4}
            textAlign="center"
            m={4}
            boxShadow="2xl"
            rounded={20}
            bg="white"
            color="white"
            transition="all 0.3s ease"
            _hover={{
              transform: "scale(1.05)",
              zIndex: 1,
              color: "black",
            }}
          >
            <Image
              src={mockup_email}
              alt="Email marketing" 
              loading="lazy" 
              w={{ base: "100%", md: "100%" }}
              mx="auto"
              mb={4}
            />
            <Text fontSize="xl" fontWeight="bold" as="h1" color="black">
              Marketing email
            </Text>
            <Text mt={2} as="h2" color="black">
              Küldj azonnali, vagy időzített emailt ügyfeleidnek, tájékoztasd
              klienseidet új szolgáltatáodról vagy kedvezményekről
            </Text>
          </Box>
        </Stack>
        <Stack
          direction={{ base: "column", md: "row" }}
          spacing={8}
          align="center"
          justify="center"
          maxW="7xl"
          mx="auto"
          mt={8}
        >
          <Box
            flex="1"
            p={4}
            textAlign="center"
            m={4}
            boxShadow="2xl"
            rounded={20}
            bg="white"
            color="white"
            transition="all 0.3s ease"
            _hover={{
              transform: "scale(1.05)",
              zIndex: 1,
              color: "black",
            }}
          >
            <SwipeableImage />
            <Text fontSize="xl" fontWeight="bold" as="h1" color="black">
              Naptár Google szinkronizálási lehetőséggel
            </Text>
            <Text mt={2} as="h2" color="black">
              Használd Google fiókodat regisztrációkor, és szinkronizáld a
              foglalásokat a saját Google naptáradba
            </Text>
          </Box>
        </Stack>
      </Flex>
    </Flex>
  );
};

export default EndPageSection;
