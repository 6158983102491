import { CheckCircleIcon } from "@chakra-ui/icons";
import {
  Box,
  Divider,
  Flex,
  Heading,
  List,
  ListIcon,
  ListItem,
  Stack,
  Text,
} from "@chakra-ui/react";
import { trial_image } from "../../assets/images/trial_image";
import { mainColor } from "./LandingPage";

const PriceSection = () => {
  return (
    <Box id="pricing" p={{ base: 0, md: 8 }} bg="white" bgColor="white">
      <Flex direction="column" align="center">
        <Text fontSize="4xl" fontWeight="bold" color={mainColor} mb={4}>
          Árak
        </Text>
        <Text fontSize="2xl" fontWeight="bold" color={mainColor} mb={4}>
          Próbáld ki ingyen 14 napig! Azonban ha novemberben regisztrálsz, 14
          nap helyett az első 2 hónapot mi álljuk!
        </Text>

        <Stack
          direction={{ base: "column", md: "row" }}
          spacing={8}
          align="center"
          justify="center"
          maxW="7xl"
          mx="auto"
          mb={30}
        >
          <Stack
            spacing={{ base: 12, md: 12, lg: 28 }}
            direction={{ base: "column", sm: "column", md: "row" }}
          >
            <Box
              pos="relative"
              // shadow="lg"
              transition="transform 0.2s"
              _hover={{ transform: "scale(1.1)" }}
            >
              <Box
                shadow="lg"
                w={{ base: 300, sm: 300, md: 300, lg: 450 }}
                bg="white"
              >
                <Box
                  w="100%"
                  pos="absolute"
                  display="flex"
                  justifyContent="right"
                >
                  {trial_image}
                </Box>
                <Box p={5}>
                  <Heading color="brand.1" fontSize="xl">
                    Havi Pro Csomag
                  </Heading>
                  <Text>Egyszerű és gyors időpont foglalás</Text>
                  <Flex mt={4}>
                    <Text as="b" color="brand.2" fontSize="3xl">
                      2 999 Ft
                    </Text>
                    <Text fontSize="md" pt={4} pl={4}>
                      Havonta
                    </Text>
                  </Flex>
                  <Box
                    mt="18px"
                    mb="18px"
                    as="button"
                    height="30px"
                    lineHeight="1.2"
                    w="100%"
                    transition="all 0.2s cubic-bezier(.08,.52,.52,1)"
                    border="1px"
                    px="8px"
                    borderRadius="2px"
                    fontSize="14px"
                    fontWeight="semibold"
                    bg="brand.1"
                    borderColor="brand.1"
                    _hover={{ bg: "brand.2" }}
                    _active={{
                      bg: "brand.2",
                      transform: "scale(0.98)",
                      borderColor: "brand.2",
                    }}
                    _focus={{
                      boxShadow:
                        "0 0 1px 2px rgba(88, 144, 255, .75), 0 1px 1px rgba(0, 0, 0, .15)",
                    }}
                    onClick={() =>
                      window.open("https://app.wobble.hu/signup_page", "_blank")
                    }
                  >
                    Kipróbálom
                  </Box>
                  <Divider colorScheme="gray" />
                  <List spacing={3}>
                    <ListItem>
                      <ListIcon as={CheckCircleIcon} color="green.500" />
                      Korlátlan szolgáltatás létrehozása
                    </ListItem>
                    <ListItem>
                      <ListIcon as={CheckCircleIcon} color="green.500" />
                      Korlátlan időpont létrehozása
                    </ListItem>
                    <ListItem>
                      <ListIcon as={CheckCircleIcon} color="green.500" />
                      Email értesítő a foglalásokról
                    </ListItem>
                    <ListItem>
                      <ListIcon as={CheckCircleIcon} color="green.500" />
                      Emlékeztető email
                    </ListItem>
                    <ListItem>
                      <ListIcon as={CheckCircleIcon} color="green.500" />
                      Google Naptár szinkronizáció
                    </ListItem>
                    <ListItem>
                      <ListIcon as={CheckCircleIcon} color="green.500" />
                      Egyedi Google Meet link generálás
                    </ListItem>
                    <ListItem>
                      <ListIcon as={CheckCircleIcon} color="green.500" />
                      Ügyfélkezelés
                    </ListItem>
                    <ListItem>
                      <ListIcon as={CheckCircleIcon} color="green.500" />
                      Személyre szabott megjelenés
                    </ListItem>
                    <ListItem>
                      <ListIcon as={CheckCircleIcon} color="green.500" />
                      Vállalkozás/szolgáltatások bemutatása
                    </ListItem>
                    <ListItem>
                      <ListIcon as={CheckCircleIcon} color="green.500" />
                      Blog poszt készítés
                    </ListItem>
                    <ListItem>
                      <ListIcon as={CheckCircleIcon} color="green.500" />
                      Email küldés
                    </ListItem>
                    <ListItem>
                      <ListIcon as={CheckCircleIcon} color="green.500" />
                      Saját Domain használat, vagy egyedi URL link, ingyenes SSL
                      tanúsítvány
                    </ListItem>
                  </List>
                </Box>
              </Box>
            </Box>

            <Box
              pos="relative"
              shadow="lg"
              transition="transform 0.2s"
              _hover={{ transform: "scale(1.1)" }}
            >
              <Box
                shadow="lg"
                w={{ base: 300, sm: 300, md: 300, lg: 450 }}
                bg="white"
              >
                <Box
                  w="100%"
                  pos="absolute"
                  display="flex"
                  justifyContent="right"
                >
                  {trial_image}
                </Box>
                <Box p={5}>
                  <Heading color="brand.1" fontSize="xl">
                    Éves Pro Csomag
                  </Heading>
                  <Text>Egyszerű és gyors időpont foglalás</Text>
                  <Flex
                    mt={4}
                    flexDirection={{ base: "column", md: "column", lg: "row" }}
                    alignItems={{
                      base: "flex-start",
                      md: "flex-start",
                      lg: "center",
                    }}
                  >
                    <Text as="del" fontSize="md" pt={4} pr={4}>
                      35 988 Ft helyett
                    </Text>
                    <Text as="b" color="brand.2" fontSize="3xl">
                      29 999 Ft
                    </Text>
                    <Text
                      fontSize="md"
                      pt={{ base: 0, md: 0, lg: 4 }}
                      pl={{ base: 0, md: 0, lg: 4 }}
                    >
                      {" "}
                      Évente
                    </Text>
                  </Flex>
                  <Box
                    mt="18px"
                    mb="18px"
                    as="button"
                    height="30px"
                    lineHeight="1.2"
                    w="100%"
                    transition="all 0.2s cubic-bezier(.08,.52,.52,1)"
                    border="1px"
                    px="8px"
                    borderRadius="2px"
                    fontSize="14px"
                    fontWeight="semibold"
                    bg="brand.1"
                    borderColor="brand.1"
                    _hover={{ bg: "brand.2" }}
                    _active={{
                      bg: "brand.2",
                      transform: "scale(0.98)",
                      borderColor: "brand.2",
                    }}
                    _focus={{
                      boxShadow:
                        "0 0 1px 2px rgba(88, 144, 255, .75), 0 1px 1px rgba(0, 0, 0, .15)",
                    }}
                    onClick={() =>
                      window.open("https://app.wobble.hu/signup_page", "_blank")
                    }
                  >
                    Kipróbálom
                  </Box>
                  <Divider colorScheme="gray" />
                  <List spacing={3}>
                    <ListItem>
                      <ListIcon as={CheckCircleIcon} color="green.500" />
                      Korlátlan szolgáltatás létrehozása
                    </ListItem>
                    <ListItem>
                      <ListIcon as={CheckCircleIcon} color="green.500" />
                      Korlátlan időpont létrehozása
                    </ListItem>
                    <ListItem>
                      <ListIcon as={CheckCircleIcon} color="green.500" />
                      Email értesítő a foglalásokról
                    </ListItem>
                    <ListItem>
                      <ListIcon as={CheckCircleIcon} color="green.500" />
                      Emlékeztető email
                    </ListItem>
                    <ListItem>
                      <ListIcon as={CheckCircleIcon} color="green.500" />
                      Google Naptár szinkronizáció
                    </ListItem>
                    <ListItem>
                      <ListIcon as={CheckCircleIcon} color="green.500" />
                      Egyedi Google Meet link generálás
                    </ListItem>
                    <ListItem>
                      <ListIcon as={CheckCircleIcon} color="green.500" />
                      Ügyfélkezelés
                    </ListItem>
                    <ListItem>
                      <ListIcon as={CheckCircleIcon} color="green.500" />
                      Személyre szabott megjelenés
                    </ListItem>
                    <ListItem>
                      <ListIcon as={CheckCircleIcon} color="green.500" />
                      Vállalkozás/szolgáltatások bemutatása
                    </ListItem>
                    <ListItem>
                      <ListIcon as={CheckCircleIcon} color="green.500" />
                      Blog poszt készítés
                    </ListItem>
                    <ListItem>
                      <ListIcon as={CheckCircleIcon} color="green.500" />
                      Email küldés
                    </ListItem>
                    <ListItem>
                      <ListIcon as={CheckCircleIcon} color="green.500" />
                      Saját Domain használat, vagy egyedi URL link, ingyenes SSL
                      tanúsítvány
                    </ListItem>
                  </List>
                </Box>
              </Box>
            </Box>
          </Stack>
        </Stack>
      </Flex>
    </Box>
  );
};

export default PriceSection;
