import { FC } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Box,
  Heading,
  Text,
  Image,
  Button,
  Container,
  Link,
} from "@chakra-ui/react";
import { blogPosts } from "./blogData";
import { Helmet } from "react-helmet";
import BlogFooter from "../BlogFooter";

type BlogPost = {
  id: number;
  title: string;
  content: Array<{
    type: "header" | "text" | "image";
    content: string;
  }>;
  thumbnail: string;
  slug: string;
};

const SingleBlogPage: FC = () => {
  const { id, title } = useParams<{ id: string; title: string }>();
  const navigate = useNavigate();

  const blogPost = blogPosts.find(
    (post: BlogPost) => post.id === parseInt(id!)
  );

  if (!blogPost || blogPost.slug !== title) {
    return (
      <Box textAlign="center" py={16}>
        <Heading as="h1" mb={4}>
          Cikk nem található
        </Heading>
        <Link onClick={() => navigate("/blog")}>
          <Box
            as="button"
            p={3}
            color="white"
            fontWeight="bold"
            borderRadius="md"
            bgGradient="linear(to-r, #5AB3C5, #007188)"
            _hover={{
              bgGradient: "linear(to-r, #024856, #5AB3C5)",
            }}
          >
            Vissza a blog oldalra
          </Box>
        </Link>
      </Box>
    );
  }

  return (
    <Box bgGradient="linear(#5AB3C5, #E9F0F1)">
      <Container
        maxW="900px"
        mx="auto"
        py={8}
        px={4}
        bg="gray.50"
        borderRadius="lg"
        boxShadow="md"
      >
        <Helmet>
          <title>{`${blogPost.title} - Wobble Blog`}</title>
          <meta
            name="description"
            content={blogPost.content.map((block) => block.content).join(" ")}
          />
          <meta property="og:title" content={blogPost.title} />
          <meta
            property="og:description"
            content={blogPost.content.map((block) => block.content).join(" ")}
          />
          <meta property="og:image" content={blogPost.thumbnail} />
          <meta
            property="og:url"
            content={`https://wobble.hu/blog/${blogPost.id}/${blogPost.slug}`}
          />
          <meta name="twitter:title" content={blogPost.title} />
          <meta
            name="twitter:description"
            content={blogPost.content.map((block) => block.content).join(" ")}
          />
          <meta name="twitter:image" content={blogPost.thumbnail} />
        </Helmet>
        <Link onClick={() => navigate("/blog")}>
          <Box
            as="button"
            p={3}
            color="white"
            fontWeight="bold"
            borderRadius="md"
            bgGradient="linear(to-r, #5AB3C5, #007188)"
            _hover={{
              bgGradient: "linear(to-r, #024856, #5AB3C5)",
            }}
          >
            Vissza a blog oldalra
          </Box>
        </Link>

        {/* Thumbnail Image */}
        <Image
          src={blogPost.thumbnail}
          alt={blogPost.title}
          borderRadius="md"
          width="100%"
          maxHeight="500px"
          objectFit="cover"
          mb={6}
          mt={8}
        />

        {/* Blog Title */}
        <Heading
          as="h1"
          mt={6}
          mb={4}
          color="#007188"
          fontSize={{ base: "3xl", md: "4xl" }}
          textAlign="center"
        >
          {blogPost.title}
        </Heading>

        {/* Render content blocks */}
        {blogPost.content.map((block, index) => {
          switch (block.type) {
            case "header":
              return (
                <Heading
                  key={index}
                  as="h2"
                  fontSize={{ base: "xl", md: "2xl" }}
                  mt={6}
                  mb={4}
                  color="#007188"
                  textAlign="left"
                >
                  {block.content}
                </Heading>
              );
            case "text":
              return (
                <Text
                  key={index}
                  fontSize={{ base: "md", lg: "lg" }}
                  color="#024856"
                  mb={4}
                  lineHeight="1.7"
                  textAlign="justify"
                  _notFirst={{ mt: 4 }}
                >
                  {block.content}
                </Text>
              );
            case "image":
              return (
                <Image
                  key={index}
                  src={block.content}
                  alt="Blog Image"
                  borderRadius="md"
                  width="100%"
                  mb={6}
                  objectFit="cover"
                />
              );
            default:
              return null;
          }
        })}
         <Link onClick={() => navigate("/blog")} mt={8}>
          <Box
            as="button"
            p={3}
            color="white"
            fontWeight="bold"
            borderRadius="md"
            bgGradient="linear(to-r, #5AB3C5, #007188)"
            _hover={{
              bgGradient: "linear(to-r, #024856, #5AB3C5)",
            }}
          >
            Vissza a blog oldalra
          </Box>
        </Link>
      </Container>
      <BlogFooter />
    </Box>
  );
};

export default SingleBlogPage;
