import "./App.css";
import BlogPage from "./components/blog/BlogPage";
import SingleBlogPage from "./components/blog/SingleBlogPage";
import LandingPage from "./components/landingPage/LandingPage";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { Routes, Route } from "react-router-dom";

function App() {
  return (
    <HelmetProvider>
      <Helmet>
        <title>Wobble - Több, mint egy időpontfoglaló</title>
        <meta
          name="description"
          content="Wobble - Több mint egy időpontfoglaló! Engedd szabadjára vállalkozásod igazi potenciálját! A Wobble időpontfoglaló rendszerével végre fellélegezhetsz: elfelejtheted a papír-toll zsonglőrködést, és kevesebb lemondással, rugalmas foglalási felülettel egyszerűsítjük a mindennapjaid.
          Próbáld ki most 14 napig teljesen ingyen, és tapasztald meg, milyen érzés, amikor az irányítás végre a te kezedben van!"
        />
        <meta
          name="keywords"
          content="időpontfoglaló, naptár, saját vállalkozás, weblap készítés, ügyfél kezelés, siker, "
        />
        <link rel="canonical" href="https://wobble.hu/" />
        <meta
          property="og:title"
          content="Wobble - Több mint egy időpontfoglaló"
        />
        <meta
          property="og:description"
          content="Wobble - Több mint egy időpontfoglaló! Engedd szabadjára vállalkozásod igazi potenciálját! A Wobble időpontfoglaló rendszerével végre fellélegezhetsz: elfelejtheted a papír-toll zsonglőrködést, és kevesebb lemondással, rugalmas foglalási felülettel egyszerűsítjük a mindennapjaid.
          Próbáld ki most 14 napig teljesen ingyen, és tapasztald meg, milyen érzés, amikor az irányítás végre a te kezedben van!"
        />
        <meta
          property="og:image"
          content="https://www.scheduleapp.com/images/og-image.jpg"
        />
        <meta property="og:url" content="https://wobble.hu/" />
        <meta property="og:type" content="website" />
      </Helmet>

      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/blog" element={<BlogPage />} />
        <Route path="/blog/:id/:title" element={<SingleBlogPage />} />
        <Route path="*" element={<div>Oldal nem található</div>} />
      </Routes>
    </HelmetProvider>
  );
}

export default App;
