import { Box, Text } from "@chakra-ui/react";

import TreeStepsSection from "./TreeStepsSection";
import MainSection from "./MainSection";
import CalendarPartSection from "./CalendarPartSection";
import FeaturesSection from "./FeaturesSection";
import PriceSection from "./PriceSection";
import DescriptionSection from "./DescriptionSection";
import Footer from "./Footer";
import Navbar from "./Navbar";
import UsageSections from "./Sections";
import Contacts from "./Contacts";

export const mainColor = "#007188";
export const darkColor = "#024856";
export const lightColor = "#5AB3C5";
export const greyColor = "#E9F0F1";

const LandingPage = () => {
  return (
    <Box bg="gray.50" color="gray.800" scrollBehavior="smooth">
      {/* Header */}
      <Navbar />
      <Box
        position="sticky"
        zIndex={1000}
        width="100%"
        bgGradient="linear(to-r, gray.300, teal.300)"
        color="white"
        py={{ base: 2, md: 4 }}
        textAlign="center"
        top={20}
      >
        <Text as="h1" fontSize={{ base: "2xl", md: "4xl" }} fontWeight="bold">
          November 4.
        </Text>
        <Text as="h2" fontSize={{ base: "xl", md: "3xl" }} fontWeight="bold">
          Gyere és próbáld ki! Ha novemberben regisztrálsz, az első 2 hónapot mi álljuk!
        </Text>
      </Box>

      {/* Hero Section */}

      <MainSection />

      {/* Dynamic Calendar Image Section */}
      <CalendarPartSection />

      {/* Integration Section */}
      <DescriptionSection />

      {/* Features Section */}
      <FeaturesSection />

      {/* 3 steps description section */}
      <TreeStepsSection />

      {/* For whose Section */}
      <UsageSections />

      {/* Pricing Section */}
      <PriceSection />

      {/* Contacts Section */}
      <Contacts />

      <Footer />
    </Box>
  );
};

export default LandingPage;
