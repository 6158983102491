import online_jelenlet from "../../assets/blog_images/online-jelenlet-idopontfoglalo.png"
import manage_business from "../../assets/blog_images/manage-business.png"

export type BlogPost = {
  id: number;
  title: string;
  thumbnail: string;
  slug: string;
  content: Array<{
    type: "header" | "text" | "image";
    content: string;
  }>;
};


const slugify = (title: string) => {
  return title
    .toLowerCase()
    .replace(/á/g, 'a')
    .replace(/é/g, 'e')
    .replace(/í/g, 'i')
    .replace(/ó/g, 'o')
    .replace(/ö/g, 'o')
    .replace(/ő/g, 'o')
    .replace(/ú/g, 'u')
    .replace(/ü/g, 'u')
    .replace(/ű/g, 'u')
    .replace(/ /g, "-")
    .replace(/[^\w-]+/g, "");
};

export const blogPosts: BlogPost[] = [
  {
    id: 1,
    title: "Online jelenlét",
    thumbnail: online_jelenlet,
    slug: slugify("Online jelenlét"),
    content: [
      { type: "header", content: "Az online időpontfoglalás és az online jelenlét egyre fontosabb szerepe" },
      { type: "text", content: "Az elmúlt években az emberek életvitele jelentősen megváltozott. A technológia fejlődése, különösen az internet és az okoseszközök elterjedése, alapjaiban formálta át a mindennapi szokásainkat, beleértve azt is, hogyan intézzük ügyeinket. Egyre több ember választja az online megoldásokat, amikor időpontot kell foglalnia, legyen szó orvosi vizsgálatról, fodrászatról vagy bármilyen szolgáltatás igénybevételéről." },
      { type: "header", content: "Miért preferálják az emberek az online időpontfoglalást?" },
      { type: "text", content: "Az egyik legfontosabb tényező az egyszerűség és a kényelem. Az online időpontfoglaló rendszerek lehetővé teszik, hogy a felhasználók bármikor és bárhonnan foglaljanak időpontot. Nincs többé szükség arra, hogy munkaidőn belül telefonáljunk, vagy személyesen intézkedjünk. A nap bármely szakaszában, akár hétvégén is elérhetőek ezek a rendszerek, így a felhasználók saját ütemük szerint szervezhetik az életüket. Egy 2021-es kutatás szerint a felhasználók 67%-a inkább online szeret időpontot foglalni, mint telefonon keresztül, mivel az gyorsabb és rugalmasabb megoldást nyújt . Ezen túlmenően a vállalkozások is profitálnak belőle, mivel az automatikus rendszer csökkenti az adminisztrációs terheket és kevesebb hibalehetőséget hagy a foglalások során." },
      { type: "header", content: "Az online jelenlét jelentősége" },
      { type: "text", content: "Az online időpontfoglalás mellett egy másik elengedhetetlen tényező a vállalkozások sikerében az online jelenlét. Egy professzionális weboldal vagy egy jól karbantartott közösségi média oldal megbízhatóságot sugall, és lehetőséget ad arra, hogy az ügyfelek könnyedén találjanak rá a szolgáltatásokra. Az emberek ma már először az interneten keresik fel a vállalkozásokat, mielőtt döntenének, hogy hol vegyék igénybe a szolgáltatásokat. Egy tanulmány szerint a vásárlók 88%-a utánanéz egy vállalkozásnak online, mielőtt döntést hozna . Ez azt mutatja, hogy a webes jelenlét és a digitális reputáció alapvető része lett annak, hogy egy vállalkozás sikeresen működjön. Ha egy cégnek nincs weboldala vagy online időpontfoglaló rendszere, az ügyfelek könnyen átvándorolhatnak a versenytársakhoz, akik ezeket a szolgáltatásokat kínálják." },
      { type: "header", content: "Miért érdemes befektetni az online rendszerekbe?" },
      { type: "text", content: "Az online időpontfoglaló rendszerek nemcsak az ügyfelek kényelmét szolgálják, hanem hatékonyabbá teszik a vállalkozás működését is. Egy jól működő rendszer automatikusan kezeli a foglalásokat, csökkenti az adminisztrációs feladatokat és minimalizálja a hibalehetőségeket. Ráadásul növeli a bizalmat is azáltal, hogy az ügyfelek láthatják az elérhető időpontokat, és azonnal tudnak foglalni anélkül, hogy sorban kellene állniuk vagy várniuk."},
      { type: "text", content: "Összegzésül, a mai világban elengedhetetlen, hogy a vállalkozások lépést tartsanak az online trendekkel, hiszen az ügyfelek egyre inkább elvárják a modern, digitális megoldásokat. Egy jól felépített online jelenlét, beleértve az online időpontfoglalási lehetőséget, nemcsak versenyelőnyt jelenthet, de alapvető feltétele annak, hogy a vállalkozás hosszú távon is sikeres maradjon."},
      { type: "text", content: 'Források: Think with Google: "How people book appointments in 2021", Statista: "Online consumer behavior trends, 2021"'}
    ]
  },

  {
    id: 2,
    title: "Miért érdemes vállalkozni? Miért éri meg képességeinket pénzre váltani?",
    thumbnail: manage_business,
    slug: slugify("Miért érdemes vállalkozni? Miért éri meg képességeinket pénzre váltani?"),
    content: [
      { type: "text", content: "A vállalkozás világa nemcsak egy életmód, hanem egy lehetőség is, hogy saját magunkat építsük, értéket teremtsünk, és a tudásunkat pénzre váltsuk. Ha valaha is gondolkodtál azon, hogy saját vállalkozást indíts, most van itt az ideje, hogy megértsd, miért lehet ez a legjobb döntés az életedben. De mi rejlik a vállalkozás hátterében? Miért érdemes a saját képességeinket pénzre váltani, és miért érdemes vállalkozásba kezdeni a mai piacon? Tudd meg, hogy mi vár rád, és hogyan vágj bele!" },
      { type: "header", content: "1. Szabadság a saját időd felett - A vállalkozás igazi előnye" },
      { type: "text", content: "A vállalkozás egyik legnagyobb előnye a szabadság, amit a saját időd és helyed felett gyakorolhatsz. Nem vagy már kiszolgáltatva egy főnöknek vagy egy rigid munkarendnek. Ha vállalkozóvá válsz, lehetőséged nyílik arra, hogy rugalmasan alakítsd a napjaidat (azt vedd figyelembe, hogy nem minden szakmai terület engedi meg a teljes hely és idő szabadságának lehetőségét). Ez persze nem azt jelenti, hogy lustizol, amíg kedvet nem kapsz egy kis munkára, pont az ellenkezője igaz: rendkívül kemény munka és kitartás szükséges, főleg az első időszakban, amikor minden teher a te válladon nyugszik." },
      { type: "text", content: "Viszont az igazi szabadság abban rejlik, hogy saját magad dönthetsz arról, mikor és hol dolgozol. Ha úgy alakul, hogy egy délelőtt inkább ügyeket intézel, például elintézel néhány személyes dolgot vagy találkozót, megteheted. A munkaidő rugalmas, tehát a nap hátralévő részében, este vagy akár hétvégén is bepótolhatod a munkát. Ezen túl, a vállalkozás lehetőséget ad arra is, hogy bárhonnan dolgozhass, nem vagy az iroda vagy a munkahelyhez kötve. Ez a fajta szabadság időben és helyben is azt jelenti, hogy könnyebben tudod összhangba hozni a magánéletedet és a munkát, és a saját tempódban haladhatsz."},
      { type: "header", content: "2. A pénzkereseti lehetőség bővítése – Hogyan váltsd pénzre a tudásod?" },
      { type: "text", content: "Ha vállalkozol, akkor lehetőséged van arra, hogy a tudásodat és képességeidet pénzre váltsd. Ha rendelkezel egy olyan készséggel, amit mások értékelnek és hajlandók fizetni érte, akkor a vállalkozás segítségével ezt a tudást értékesítheted. Legyen szó tanácsadásról, programozásról, szövegírásról, vagy akár fizikai termékekről, a lehetőségek végtelenek. A saját vállalkozás a tudásod piacképessé tételét jelenti, és ha kitartóan dolgozol, akkor az anyagi eredmény sem fog elmaradni."},
      { type: "header", content: "3. A munka melletti vállalkozás – Hogyan kezdj neki anélkül, hogy mindent feladnál?"},
      { type: "text", content: "Nem mindenkinek adatik meg, hogy teljes munkaidőben vállalkozzon, különösen akkor, ha már egy stabil állásban dolgozik. Azonban a munka melletti vállalkozás egy remek lehetőség arra, hogy fokozatosan építsd fel saját vállalkozásodat anélkül, hogy teljesen lemondanál a biztos jövedelemről. A munka mellett kezdett vállalkozás előnye, hogy megtartja a pénzügyi stabilitást, miközben lehetőséget ad arra, hogy kipróbáld, hogyan működik az üzlet, és milyen piacra lépni. Egy munka melletti vállalkozás segíthet abban, hogy alacsony kockázattal és fokozatosan haladj előre. Emellett könnyen kialakíthatod a megfelelő időbeosztást, hiszen a munkahelyi feladataid mellett, a vállalkozásban való részvétel a szabadidődből vehet el időt. Persze nem lesz könnyű – kevesebb szabadidővel kell szembenézned, és gyakran hosszú órákat kell dolgoznod a munkahelyeden, majd a vállalkozásban is. De ha sikerül összhangba hozni a kettőt, és a vállalkozás beindul, jó jövedelemkiegészítő, és később dönthetsz arról, hogy teljesen elhagyod a munkahelyedet."},
      { type: "header", content:"4. A teljesen független vállalkozás – A végső szabadság?" },
      { type: "text", content:"Ha sikerült a munka melletti vállalkozásod felépíteni, elérheted azt a pillanatot, amikor már nem szükséges többé alkalmazotti munkát végezned. A teljesen független vállalkozás nemcsak a pénzügyi szabadságot adja meg, hanem a személyes életminőséget is. Az egyik legnagyobb előnye, hogy a munkát és a magánéletet te osztod be, és valódi szabadságot élvezhetsz a döntéseid felett. A teljes függetlenség eléréséhez azonban komoly előkészítés szükséges. Nagyon fontos, hogy a vállalkozásod stabil alapokra épüljön, és hogy a jövedelmed biztos forrást adjon a megélhetéshez. Emellett a döntéseid hatással lesznek a vállalkozás hosszú távú sikerére, ezért érdemes olyan irányt választani, amit valóban szenvedéllyel tudsz művelni. A független vállalkozás elérése nem könnyű, de ha elég kitartó vagy, és megfelelő stratégiát választasz, akkor az eredmény megéri az erőfeszítést." },
      { type: "header", content: "5. A nehézségek – Miért nem könnyű vállalkozni?"},
      { type: "text", content: "A vállalkozás nem egy egyszerű út, és ennek megértése elengedhetetlen. Kezdetben minden új, minden ismeretlen, és a vállalkozó gyakran egy személyben végzi el az összes feladatot: marketing, ügyfélszerzés, pénzügyi tervezés, jogi kérdések – mindent neked kell kezelni. A legfontosabb ebben az időben a folyamatos tanulás. Az első hónapok, akár évek, tele lehetnek kihívásokkal, amikor minden döntés és feladat a te kezedben összpontosul. Az eladásokat növelni kell, a költségeket optimalizálni, és ügyfeleket szerezni, mindezt úgy, hogy közben figyelsz a minőségre és a márkaépítésre is."},
      { type: "text", content: "Azonban az idő múlásával, amikor a vállalkozás növekszik, elérkezik az a pont, ahol már nem végezheted el mindent egyedül. Itt jön a kulcsfontosságú lépés: meg kell tanulnod kiszervezni a feladatokat a megfelelő szakembereknek, munkatársaknak. Az alkalmazottak vagy külső partnerek bevonása lehetővé teszi, hogy te a legfontosabb dolgokra összpontosíts, miközben mások elvégzik azokat a területeket, amelyekre nem te vagy a legalkalmasabb. A kezdeti nehézségek tehát, bár nagyon kemények, végül a fejlődés és a tanulás eszközeivé válhatnak, és segíthetnek abban, hogy a vállalkozásod hosszú távon sikeres és fenntartható legyen."},
      { type: "header", content: "6. A kudarcokból való tanulás – Miért lehet előny a nehézségekkel való szembenézés?"},
      { type: "text", content: "A vállalkozás világában nemcsak a siker, hanem a kudarc is fontos része a tanulásnak. A sikeres vállalkozók többsége előbb vagy utóbb szembesül valamilyen kudarcélménnyel. A kulcs az, hogy miként kezeled a nehéz helyzeteket. A kudarcok nem végállomások, hanem lépcsőfokok a siker felé. Ha az első próbálkozás nem hozza meg a kívánt eredményeket, az nem jelenti azt, hogy fel kell adnod. A vállalkozásba való belevágás az egyik legnagyobb lehetőség arra, hogy saját magadat fejleszd, hogy alkalmazkodj, tanulj, és új utakat találj. Mindezek a tapasztalatok az idő múlásával segítenek egy erősebb és sikeresebb vállalkozás felépítésében."},
      { type: "header", content: "7. A vállalkozás előnyei – Miért éri meg végül?"},
      { type: "text", content: "A legnagyobb előny, amit a vállalkozás nyújt, az az, hogy képes vagy irányítani a sorsodat. Ha sikerül megtalálni a megfelelő piacot, ügyféltábort és üzleti modellt, akkor a vállalkozás nemcsak anyagi sikerhez vezethet, hanem egy olyan életformához, ahol te magad alkothatod meg a szabályokat. A vállalkozás a pénzügyi szabadság kulcsa, hiszen ha helyesen építed fel, akkor a bevételek folyamatosan nőhetnek. A vállalkozás tehát nemcsak egy lehetőség, hanem egy kihívás is, amely arra ösztönöz, hogy kibővítsd saját határaidat, és olyan dolgokat érj el, amiket alkalmazottként valószínűleg nem tudnál. Bár nem mindenki számára való, azok, akik sikerrel átvészelik a nehézségeket, hatalmas jutalmakat élvezhetnek."},
      { type: "header", content: "8. A sikeres vállalkozás titkai: Hogyan vágj bele?"},
      { type: "text", content: "Ha úgy érzed, készen állsz a vállalkozás indítására, kezdj el dolgozni a legfontosabb alapokon. Készíts egy üzleti tervet, határozd meg a céljaidat és azokat a lépéseket, amelyek segítenek a megvalósításban. Kezdj el keresni azokat a lehetőségeket, amelyek a piacon betöltött rést kitöltik, és próbálj folyamatosan tanulni a vállalkozás különböző aspektusairól. Ne hagyd, hogy a félelem megakadályozzon abban, hogy belevágj!"},
      { type: "text", content: 'Túl sokat látod hogy "kezd el most!", "egyszerű manapság egy vállalkozás indítása!" mondatokat, de úgy érzed nem igaz és nem tudod merre? Elhiheted, én tudom hogy érzel. A Wobble-t azért hoztuk létre, hogy ezt az érzést csökkentsük, és minden induló egyéni-és kisvállalkozónak meglegyen az az kiinduló pont, ami külön-külön kezelve idő és pénz. Online jelenlét, időpontfoglaló, email, blog, ügyfélkezelés, és ami talán a legfontosabb, automatizálás. Kérdésed van, keress bátran! Segítek!'}
    ]
  },

];
